export const WhatThe = () => {
  return (
    <>
      <div className="py-10 px-5">
        <article className="prose">
          <h2>What the loom..</h2>
          <p>
            로그인 없이도 채팅방에 바로 입장할 수 있어요.
            <br /> 닉네임만 입력하면 바로 채팅을 시작할 수 있습니다. 불필요한
            절차 없이 즉시 이용 가능한 간편한 서비스를 제공합니다.
          </p>
          <p>
            더 많은 기능을 사용하고 싶다면, 이메일을 통한 회원 가입을 하시면
            됩니다. 회원 가입 후에는 다양한 기능을 이용하실 수 있습니다.
          </p>
          <p>
            그 외에도 다양한 기능들이 준비되어 있으니, 지금 바로 저희 채팅앱을
            이용해보세요! 감사합니다.
          </p>
        </article>
      </div>
    </>
  );
};
