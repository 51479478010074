export const PrivacyPolicy = () => {
  return (
    <>
      <div className="py-10 px-5">
        <article className="prose">
          <h2>개인 정보 정책</h2>
          <p>
            당신의 개인정보 보호는 우리에게 매우 중요합니다. 이에 대한 자세한
            정보는 아래와 같이 안내해드리겠습니다.
          </p>
          <h4>수집하는 개인정보 항목</h4>
          <p>
            우리 앱은 사용자의 닉네임, 이메일 주소 및 위치 정보 등을 수집할 수
            있습니다.
          </p>
          <h4>개인정보의 이용 목적</h4>
          <p>우리 앱은 사용자의 개인정보를 다음과 같은 목적으로 사용합니다.</p>
          <ul>
            <li>회원 가입 및 관리</li>
            <li>서비스 제공 및 유지 보수</li>
            <li>이벤트나 프로모션 정보 제공</li>
            <li>문의사항에 대한 응답 및 불만처리</li>
          </ul>
          <h4>개인정보의 보유 및 이용 기간</h4>
          <p>
            우리 앱은 사용자가 회원 탈퇴를 요청할 경우, 수집된 개인정보를 즉시
            파기합니다. 단, 법령에서 정하는 기간 동안에는 보관될 수 있습니다.
          </p>
          <h4>개인정보의 파기 절차 및 방법</h4>
          <p>
            우리 앱은 사용자가 회원 탈퇴를 요청한 경우, 수집된 개인정보를 즉시
            파기합니다. 개인정보는 기록하거나 저장된 전자적 파일 형태로 저장된
            경우, 기록을 재생할 수 없는 기술적 방법을 사용하여 완전히
            삭제합니다.
          </p>
          <h4>개인정보 제공 및 공유</h4>
          <p>
            우리 앱은 사용자의 개인정보를 외부에 제공하거나 공유하지 않습니다.
            단, 법령에 의해 요구되는 경우나 사용자의 동의를 받은 경우는
            예외적으로 제공될 수 있습니다.
          </p>
          <h4>개인정보 처리 위탁</h4>
          <p>우리 앱은 개인정보 처리를 외부 업체에 위탁하지 않습니다.</p>
          <h4>이용자의 권리와 의무</h4>
          <p>
            사용자는 언제든지 자신의 개인정보를 열람하거나 수정, 삭제 요청할 수
            있습니다. 단, 회원 탈퇴 요청 시 해당 계정의 이용 기록 등이 남을 수
            있습니다.
          </p>
          <h4>개인정보 보호책임자</h4>
          <p>
            우리 앱은 사용자의 개인정보 보호에 최선을 다하며, 개인정보 보호에
            대한 불만사항 및 문의사항에 대해서는 아래 연락처로 문의해주시기
            바랍니다.
          </p>
          <ul>
            <li>이름: fanta kim</li>
            <li>이메일: scumm.loom@gmail.com</li>
          </ul>
        </article>
      </div>
    </>
  );
};
